<template>
  <div class="text-right bg" :style="{height:heights+'px'}" :class="client == 'iOS' ? 'iosClass' : ''">
    <div class="dflexs  head" >
      <img class="returns" @click='returns' src="../assets/level/imgin/return.png" alt="">
      <div class="pagetitle">{{$t('Level.title')}}</div>
    </div>
    <div>
      <img style="width: 100%;height: 100%;position: relative;margin-top: 5px;" src="../assets/level/bg1.png" alt="">
      <div class="dingwei" style="">
        <div class="huadongg">
          <div class="beijin">
              <span class="personLevel" @click='levelClick(0)' :title="$t('Level.personLevel')">{{$t('Level.personLevel')}}
                <img v-show="urlSta == 0" class="" style="width: 0.46rem;" :src="imgfx" alt="">
              </span>
            <span class="wealthLevel" @click='levelClick(1)' :title="$t('Level.wealthLevel')">{{$t('Level.wealthLevel')}}
              <img v-show="urlSta == 1" class="" style="width: 0.46rem;" :src="imgfx" alt="">
            </span>
            <span class="charmLevel" @click='levelClick(2)' :title="$t('Level.charmLevel')">{{$t('Level.charmLevel')}}
              <img v-show="urlSta == 2" class="" style="width: 0.46rem;" :src="imgfx" alt="">
            </span>
          </div>
          <van-swipe @change="onClick" height="100%" :show-indicators="false" ref="swiper">
            <van-swipe-item>
              <div>
                <div style="padding-top:0.5rem ;">
                  <div style="font-size: 0.2rem;color: #fff; display: flex;justify-content: center;">
                    <div class="urlRank" :style="{'backgroundImage':'url('+personLevelData.personalLevelUrl+')'}">
                      <div style="padding-right: 0.27rem;line-height: 1;text-align: right;">
                        <div class="urlRank_box" style=" font-size: 0.2rem;font-family: Regular;">{{$t('Level.personLevel')}}</div>
                        <span style="font-size: 0.6rem;font-family: Semibold;">{{personLevelData.personalNowLevel}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" currentExpbox">
                  <div style="color: #deb78870;padding-bottom: 0.06rem;font-size: 0.15rem;line-height: 1;">
                    {{$t('Level.currentExp')}}
                    {{personLevelData.personalNowLevelExp}}，LV.{{personLevelData.personalLevelRight}}
                    {{$t('Level.stillNeed')}}
                    {{personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'0':personLevelData.nowLevelExp-personLevelData.personalNowLevelExp}}
                  </div>
                  <div class="progress_barbox">
                    <div class="currentExp" :style="{width: (personLevelData.nowLevelExp-personLevelData.personalNowLevelExp&lt;=0?'1':personLevelData.personalNowLevelExp/personLevelData.nowLevelExp)*100 +'%'}">
                    </div>
                  </div>
                  <div style="color: #DEB888;padding-bottom: 0.1rem;display: flex;justify-content: center;margin-top: 0.17rem;font-size: 0.2rem;">
                    <span style="flex: 1;">LV.{{personLevelData.personalLevelLeft}}</span>
                    <span>LV.{{personLevelData.personalLevelRight}}</span>
                    <!-- <span v-else>MAX</span> -->
                  </div>
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div>
                <div style="padding-top:0.5rem ;">
                  <div style="font-size: 0.2rem;color: #fff; display: flex;justify-content: center;">
                    <div class="urlRank" :style="{'backgroundImage':'url('+wealthLevelData.wealthLevelUrl+')'}">
                      <div style="padding-right: 0.27rem;line-height: 1;text-align: right;">
                        <div class="urlRank_box" style="font-size: 0.2rem;font-family: Regular;">{{$t('Level.wealthLevel')}}</div>
                        <span style="font-size: 0.6rem;font-family: Semibold;">{{wealthLevelData.wealthNowLevel}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" currentExpbox">
                  <div style="color: #deb78870;padding-bottom: 0.06rem;font-size: 0.15rem;line-height: 1;">
                    {{$t('Level.currentExp')}}
                    {{wealthLevelData.personalNowWealthLevelExp}}，LV.{{wealthLevelData.wealthLevelRight}}
                    {{$t('Level.stillNeed')}}
                    {{wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'0':wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp}}
                  </div>
                  <div class="progress_barbox">
                    <div class="currentExp" :style="{width: (wealthLevelData.nowWealthLevelExp-wealthLevelData.personalNowWealthLevelExp&lt;=0?'1':wealthLevelData.personalNowWealthLevelExp/wealthLevelData.nowWealthLevelExp)*100 +'%'}">
                    </div>
                  </div>
                  <div style="color: #DEB888;padding-bottom: 0.1rem;display: flex;justify-content: center;margin-top: 0.17rem;font-size: 0.2rem;">
                    <span style="flex: 1;">LV.{{wealthLevelData.wealthLevelLeft}}</span><span>LV.{{wealthLevelData.wealthLevelRight}}</span>
                  </div>
                </div>
              </div>
            </van-swipe-item>

            <van-swipe-item>
              <div>
                <div style="padding-top:0.5rem ;">
                  <div style="font-size: 0.2rem;color: #fff; display: flex;justify-content: center;">
                    <div class="urlRank" :style="{'backgroundImage':'url('+charmLevelInfo.glamourLevelUrl+')'}">
                      <div style="padding-right: 0.27rem;line-height: 1; text-align: right;">
                        <div class="urlRank_box" style="font-size: 0.2rem;font-family: Regular;">{{$t('Level.charmLevel')}}</div>
                        <span style="font-size: 0.6rem;font-family: Semibold;">{{charmLevelInfo.glamourNowLevel}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" currentExpbox">
                  <div style="color: #deb78870;padding-bottom: 0.06rem;font-size: 0.15rem;line-height: 1;">
                    {{$t('Level.currentExp')}}
                    {{charmLevelInfo.personalNowGlamourLevelExp}}，LV.{{charmLevelInfo.nowGlamourLevelExp}}
                    {{$t('Level.stillNeed')}}
                    {{charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'0':charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp}}
                  </div>
                  <div class="progress_barbox">
                    <div class="currentExp" :style="{width: (charmLevelInfo.nowGlamourLevelExp-charmLevelInfo.personalNowGlamourLevelExp&lt;=0?'1':charmLevelInfo.personalNowGlamourLevelExp/charmLevelInfo.nowGlamourLevelExp)*100 +'%'}">
                    </div>
                  </div>
                  <div style="color: #DEB888;padding-bottom: 0.1rem;display: flex;justify-content: center;margin-top: 0.17rem;font-size: 0.2rem;">
                    <span style="flex: 1;">LV.{{charmLevelInfo.glamourLevelLeft}}</span><span>LV.{{charmLevelInfo.glamourLevelRight}}</span>
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- <div class="jianju" style="height: 50px"></div> -->
        <div style=" overflow: hidden;" :style="{'maxHeight':slideh+'px'}">
          <div style="overflow: scroll; " :style="{'maxHeight':slideh+'px'}">
            <span>
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.badgeReward')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div style="margin-bottom: 0.2rem;" v-if="activeIndicator==0">
                <van-grid :border="false" :column-num="4">
                  <van-grid-item v-for="(item, index) in badgeReward.person" :key="index">
                    <!-- <van-image src="../assets/level/1@3x.png" /> -->
                    <img style="height: 1.34rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
              <div style="margin-bottom: 0.2rem;" v-if="activeIndicator==1">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in badgeReward.wealth" :key="index">
                    <!-- <van-image src="../assets/level/1@3x.png" /> -->
                    <img style="height: 1.34rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
              <div style="margin-bottom: 0.2rem;" v-if="activeIndicator==2">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in badgeReward.charmLevel" :key="index">
                    <!-- <van-image src="../assets/level/1@3x.png" /> -->
                    <img style="height: 1.34rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
            </span>
            <!-- <div style="margin-bottom: 0.4rem;">
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.avatarFrame')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div class="avatarFrame">
                <div v-for="(item, index) in heads" :key="index">
                  <img class="ffectsimg" style="" :src="item.url" alt="">
                </div>
              </div>
            </div> -->

            <div style="margin-bottom: 0.4rem;">
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.avatarFrame')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div class="avatarFrame">
                <div v-for="(item, index) in heads" :key="index" class="avatarFramebox">
                  <img style="width: 1.2rem;height: 1.2rem;" :src="item.url" alt="">
                  <span class="rankvalue" style="margin: 0.2rem 0;">LV.{{item.level}}</span>
                </div>
              </div>
            </div>

            <div style="margin-bottom: 0.4rem;">
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.entranceEffect')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div class="avatarFrame">
                <div v-for="(item, index) in mounts" :key="index" class="avatarFramebox">
                  <img style="width: 1.2rem;height: 1.2rem;" :src="item.url" alt="">
                  <span class="rankvalue" style="margin: 0.2rem 0;">LV.{{item.level}}</span>
                </div>
              </div>
            </div>

            <div style="margin-bottom: 0.4rem;">
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.upgrade')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div class="help">
                <div v-if="activeIndicator==0" class="flexs upgradebox" v-for="(item, index) in $t('Level.upgradebox.person')" :key="index">
                  <img v-if="index==0" style="width: 0.85rem;" src="../assets/level/Sendgift.png">
                  <img v-if="index==1" style="width: 0.85rem;" src="../assets/level/tasks.png">
                  <div class="contentbox">
                    <div class="titleName">{{item.title}}</div>
                    <div style="color: #DEB888;font-size: 0.2rem;">{{item.value}}</div>
                  </div>
                </div>
                <div v-if="activeIndicator==1" class="flexs upgradebox" v-for="(item, index) in $t('Level.upgradebox.wealth')" :key="index">
                  <img v-if="index==0" style="width: 0.85rem;" src="../assets/level/Sendgift.png">
                  <img v-if="index==1" style="width: 0.85rem;" src="../assets/level/tasks.png">
                  <div class="contentbox">
                    <div class="titleName">{{item.title}}</div>
                    <div style="color: #DEB888;font-size: 0.2rem;">{{item.value}}</div>
                  </div>
                </div>


                <div v-if="activeIndicator==2" class="flexs upgradebox" v-for="(item, index) in $t('Level.upgradebox.charm')" :key="index">
                  <img v-if="index==0" style="width: 0.85rem;" src="../assets/level/Sendgift.png">
                  <img v-if="index==1" style="width: 0.85rem;" src="../assets/level/tasks.png">
                  <div class="contentbox">
                    <div class="titleName">{{item.title}}</div>
                    <div style="color: #DEB888;font-size: 0.2rem;">{{item.value}}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script type="text/babel">
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
import leftimg from '../assets/level/left.png';
import rightimg from '../assets/level/right.png';
import leftbr from '../assets/level/leftbr.png';
import rightbr from '../assets/level/rightbr.png';
import bottomurl from '../assets/level/imgin/tab.png';

//个人等级
import LV1_10 from '../assets/level/imgin/LV1-10.png';
import LV11_20 from '../assets/level/imgin/LV11-20.png';
import LV21_30 from '../assets/level/imgin/LV21-30.png';
import LV31_40 from '../assets/level/imgin/LV31-40.png';
import LV41_50 from '../assets/level/imgin/LV41-50.png';
import LV51_60 from '../assets/level/imgin/LV51-60.png';
import LV61_70 from '../assets/level/imgin/LV61-70.png';
import LV71_80 from '../assets/level/imgin/LV71-80.png';
import LV81_90 from '../assets/level/imgin/LV81-90.png';
import LV91_100 from '../assets/level/imgin/LVLV91-100.png';

//财富等级
import cfLV1_15 from '../assets/level/imgin/LVv1-10.png';
import cfLV16_30 from '../assets/level/imgin/LVv11-20.png';
import cfLV31_45 from '../assets/level/imgin/LVv21-30.png';
import cfLV46_60 from '../assets/level/imgin/LVv31-40.png';
import cfLV61_75 from '../assets/level/imgin/LVv41-50.png';
import cfLV76_99 from '../assets/level/imgin/LVv51-60.png';

//魅力等级
import mlLV1_15 from '../assets/level/imgin/1-15.png';
import mlLV16_30 from '../assets/level/imgin/16-30.png';
import mlLV31_45 from '../assets/level/imgin/31-45.png';
import mlLV46_60 from '../assets/level/imgin/46-60.png';
import mlLV61_75 from '../assets/level/imgin/61-75.png';
import mlLV76_100 from '../assets/level/imgin/76-100.png';

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      donghua: '',
      danmus: [],
      // personLevelData: {
      //   currentExp: 1000,
      //   rquiredExp: 3000,
      //   currentRank: 12,
      //   nextRank: 13,
      // },
      personLevelData: [],
      wealthLevelData: [],
      heads: [],
      mounts: [],
      personHeads: [],
      personMounts: [],
      wealthHeads: [],
      wealthMounts: [],
      charmLevelInfo: [],

      urlRank: '',

      tasklist: null,
      index: 0,
      disabled: false,
      status: true,
      headers: {
        deviceId: '',
        lang: '',
        version: '',
        os: '',
        model: '',
        fid: ''
      },
      recordlist: [],
      userInfo: [],
      emptyRecord: false,
      token: null,
      uid: null,
      headers: null,
      // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOREV5IiwieHl6IjoiWmpsalpEUTVZakpqT0daaU5HTmlNV0ZpTnpWbFkyWmlNell3WmpnNVlqVT0iLCJleHAiOjE5MDc3NDM3NDB9.irA-gNwNYSD9s6_BQH2nwiQU8Zprq5BS2nyHhqwIRYY',
      // uid: 412,
      // headers: [
      //   'Content-Type:' + 'application/json; charset=utf-8',
      //   'deviceId:' + 'f9cd49b2c8fb4cb1ab75ecfb360f89b5',
      //   'lang:' + 'zh_CN',
      //   'version:' + '2.2.0',
      //   'os:' + '1',
      //   'model:' + 'M2011K2C',
      //   'fid:' + '0',
      // ],
      myiCode: '',
      active: '',
      width: 55,
      imgfx: bottomurl,
      leftbr: leftbr,
      rightbr: rightbr,
      heights: 0,
      slideh: 0,
      badgeReward: {
        person: [
          { lvimg: LV1_10, value: 'LV.1-10' },
          { lvimg: LV11_20, value: 'LV.11-20' },
          { lvimg: LV21_30, value: 'LV.21-30' },
          { lvimg: LV31_40, value: 'LV.31-40' },
          { lvimg: LV41_50, value: 'LV.41-50' },
          { lvimg: LV51_60, value: 'LV.51-60' },
          { lvimg: LV61_70, value: 'LV.61-70' },
          { lvimg: LV71_80, value: 'LV.71-80' },
          { lvimg: LV81_90, value: 'LV.81-90' },
          { lvimg: LV91_100, value: 'LV.91-100' }
        ],
        wealth: [
          { lvimg: cfLV1_15, value: 'LV.1-15' },
          { lvimg: cfLV16_30, value: 'LV.16-30' },
          { lvimg: cfLV31_45, value: 'LV.31-45' },
          { lvimg: cfLV46_60, value: 'LV.46-60' },
          { lvimg: cfLV61_75, value: 'LV.61-75' },
          { lvimg: cfLV76_99, value: 'LV.76-99' },
        ],
        charmLevel: [
          { lvimg: mlLV1_15, value: 'LV.1-15' },
          { lvimg: mlLV16_30, value: 'LV.16-30' },
          { lvimg: mlLV31_45, value: 'LV.31-45' },
          { lvimg: mlLV46_60, value: 'LV.46-60' },
          { lvimg: mlLV61_75, value: 'LV.61-75' },
          { lvimg: mlLV76_100, value: 'LV.76-100' },
        ],
      },
      activeIndicator: 0,
      str1: '',
      urlSta: '',
      client:''
    };
  },

  created() {
    // this.$completes.loadErudaJs()
    // this.personLevel()
    // this.invitationRecord()

    $(function () {
      $('.beijin>span').click(function () {
        $(this).css('color', '#DEB888')
        $(this).siblings().css('color', 'rgb(216 186 150 / 30%)')
      })
    })
    this.judgeClient()

  },
  mounted() {
    //页面css初始化
    // document.getElementsByTagName("html")[0].style.backgroundColor = "#8260FF";
    document.documentElement.style.overflow = 'hidden';
    document.getElementsByTagName("html")[0].style.padding = "0";
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    // this.getUserInfo();
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    this.heights = window.screen.height
    var huadongg = document.getElementsByClassName('huadongg');
    this.$nextTick(()=>{
      this.slideh = window.screen.height - huadongg[0].offsetHeight
    })
    
    // let payment_url = 'https://help.koudailive.com/pact/#/familyLevel?type=1%3FlangType%3Dzh_CN'
    let payment_url = location.hash
    console.log(payment_url,'payment_url');
    let askIndex = payment_url.indexOf('?');
    var newStr = payment_url.slice(askIndex);
    let strs = decodeURIComponent(newStr.split('=')[1])
    let ipos = strs.indexOf("?");//指定开始的字符串
    let str1s = strs.slice(0, ipos)
    this.str1 = str1s
    if(str1s == 0) {
      this.levelClick(0)
    }
     else if (str1s == 1) {
      this.levelClick(1)
    }
    else if (str1s == 2) {
      this.levelClick(2)
    }
    else{
      this.levelClick(1)
    }
  },


  methods: {
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    //返回
    returns() {
      this.$completes.complete("getPreviousPage");
    },

    //原生返回参数
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      const userinfos = Cookies.get('userinfos')
      if (userinfos != undefined) {
        if (userinfos.uid == this.uid) {
          this.myiCode = userinfos.myiCode
        }
      }
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      // let langs = 'zh'
      // let lang = 'zh'
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      // this.$i18n.locale = 'ar'
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('inviteCash.title')
      this.personLevel()

    },

    leftslip() {
      this.imgfx = bottomurl
      $('.beijin>span').eq(1).css('color', '#DEB888')
      $('.beijin>span').eq(0).css('color', 'rgb(216 186 150 / 30%)')
      $('.beijin>span').eq(2).css('color', 'rgb(216 186 150 / 30%)')
    },
    rightslip() {
      this.imgfx = bottomurl
      $('.beijin>span').eq(0).css('color', '#DEB888')
      $('.beijin>span').eq(1).css('color', 'rgb(216 186 150 / 30%)')
      $('.beijin>span').eq(2).css('color', 'rgb(216 186 150 / 30%)')
    },

    charmColor(){
      this.imgfx = bottomurl
      $('.beijin>span').eq(2).css('color', '#DEB888')
      $('.beijin>span').eq(0).css('color', 'rgb(216 186 150 / 30%)')
      $('.beijin>span').eq(1).css('color', 'rgb(216 186 150 / 30%)')
    },

    levelClick(idx) {
      this.urlSta = idx;
      if (idx == 1) {
        this.leftslip()
        if (idx != this.$refs.swiper.activeIndicator) {
          this.$refs.swiper.swipeTo(idx)
        }
      }
      else if (idx == 0) {
        this.rightslip()
        if (idx != this.$refs.swiper.activeIndicator) {
          this.$refs.swiper.swipeTo(idx)
        }
      }else{
        this.charmColor()
        this.$refs.swiper.swipeTo(idx)
      }
    },

    onClick(idx) {
      this.urlSta = idx;
      this.activeIndicator = this.$refs.swiper.activeIndicator
      if (idx == 1) {
        this.leftslip()
        this.heads = this.wealthHeads
        this.mounts = this.wealthMounts
      }
      else if (idx == 0) {
        this.rightslip()
        this.heads = this.personHeads
        this.mounts = this.personMounts
      }else{
        this.charmColor()
        this.heads = ""
        this.mounts = ""
      }
    },

    personLevel() {
      let that = this
      //得到用户个人等级
      let personLeveldatas = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserLevelInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          console.log(data,"data");
          that.personLevelData = data.data
        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });
      //得到用户个人等级头像框
      let personLevelheads = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserLevelFramelessHeads?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLevelheads).then((data) => {
        if (data.code == 200) {
          that.personHeads = data.data
          if (that.str1 == 0) {
            that.heads = data.data
          }

        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });
      //得到用户个人等级坐骑
      let personLevelmounts = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserLevelMount?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLevelmounts).then((data) => {
        if (data.code == 200) {
          that.personMounts = data.data
          if (that.str1 == 0) {
            that.mounts = data.data
          }
        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });


      //得到用户财富等级
      let wealthLeveldatas = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserWealthLevelInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(wealthLeveldatas).then((data) => {
        if (data.code == 200) {
          console.log(data,"data2222");
          that.wealthLevelData = data.data
        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });
      //得到用户财富等级头像框
      let wealthLevelheads = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserWealthLevelFramelessHeads?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(wealthLevelheads).then((data) => {
        if (data.code == 200) {
          that.wealthHeads = data.data
          if (that.str1 == 1) {
            that.heads = data.data
          }
        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });
      //得到用户财富等级坐骑
      let wealthLevelmounts = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserWealthLevelMount?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(wealthLevelmounts).then((data) => {
        if (data.code == 200) {
          that.wealthMounts = data.data
          if (that.str1 == 1) {
            that.mounts = data.data
          }
        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });

      let charmLevelData = {
        option: 'get',
        host: this.$serviceIp + '/api/user/getUserGlamourLevelInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(charmLevelData).then((data) => {
        if (data.code == 200) {
          that.charmLevelInfo = data.data
        }
        this.slideh = window.screen.height - huadongg[0].offsetHeight
      });
    },

  },

};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */

html[lang="ar"] .text-right {
  /* direction: rtl; */
}

html[lang="ar"] .head {
  direction: rtl;
}

html[lang="ar"] .pagetitle {
  margin-left: 0.4rem;
  margin-right: 0;
}

html[lang="ar"] .returns {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .beijin {
  direction: rtl;
}

html[lang="ar"] .beijin > img {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .personLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;
  /* text-align: left; */
}
html[lang="en"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}
html[lang="pt"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}
html[lang="es"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}
html[lang="tr"] .personLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
}

html[lang="ar"] .wealthLevel {
  /* padding-right: 0.5rem; */
  padding-left: 0;
  color: rgb(216 186 150 / 30%);
  width: 30%;
  text-align: center;
  /* text-align: right; */
}
html[lang="ar"] .charmLevel {
  padding-left: 0.5rem;
  padding-right: 0;
  width: 30%;
  /* text-align: center; */
  /* text-align: right; */
}

html[lang="en"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;

}
html[lang="pt"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;

}
html[lang="es"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;

}
html[lang="tr"] .charmLevel {
  padding-right: 0.5rem;
  padding-left: 0;
  width: 30%;

}

html[lang="ar"] .currentExpbox {
  direction: rtl;
}

html[lang="ar"] .van-grid {
  direction: rtl;
}

html[lang="ar"] .avatarFrame {
  direction: rtl;
}

html[lang="ar"] .ffectsimg {
  margin-right: 0;
  margin-left: 0.2rem;
}

html[lang="ar"] .avatarFramebox {
  margin-right: 0;
  margin-left: 0.2rem;
}

html[lang="ar"] .help {
  direction: rtl;
}

html[lang="ar"] .contentbox {
  text-align: right;
  margin-right: 0.3rem;
}

.van-swipe {
  padding-bottom: 1.4rem;
}

.bg {
  overflow: hidden;
}

.box {
  position: relative;
  margin-top: 88px;
}

.head {
  display: flex;
  align-items: center;
  background: #1d1d1d;
  justify-content: center;
  padding: 0 0.27rem;
  height: 44px;
  margin: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  padding-top: 44px;
}

.returns {
  width: 0.4rem;
  height: 0.4rem;
}

.pagetitle {
  font-size: 0.3rem;
  font-family: Regular;
  color: #ffffff;
  line-height: 0.5rem;
  flex: 1;
  text-align: center;
  margin-right: 0.4rem;
}

.flexs {
  display: flex;
  align-items: center;
}

.dingwei {
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}

.van-tab--active {
  color: #323233;
  font-weight: 600;
}

.urlRank {
  width: 2.8rem;
  min-height: 0.97rem;
  /* background-image: url('../assets/level/geren45.png'); */
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.urlRank img {
  width: 0.3rem;
  height: 0.28rem;
  margin-left: 0.1rem;
}

.progress_barbox {
  width: 100%;
  height: 0.13rem;
  background: #ffedd620;
  border-radius: 7px;
  font-size: 0.2rem;
  position: relative;
  overflow: hidden;
}

.currentExpbox {
  height: 1rem;
  font-size: 0.25rem;
  padding: 0.46rem 0.76rem 0;
  font-family: Regular;
}

.currentExp {
  background: #deb888;
  height: 0.13rem;
  border-radius: 7px;
}

.rank {
  padding: 0 0.1rem;
}

.beijin {
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Semibold;
  padding-top: 116px;
  max-height: 22px;
}

.personLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  color: #deb888;
  text-align: center;
}

.wealthLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-left: 0.5rem; */
  color: rgb(216 186 150 / 30%);
  text-align: center;
}

.charmLevel{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  color: rgb(216 186 150 / 30%);
  text-align: center;
}

html {
  background: #000;
  overflow-x: hidden;
  height: 11.3rem;
}

.reward {
  color: #deb888;
  font-size: 0.25rem;
  font-family: Regular;
}

.rankExh {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border: 1px solid #deb888;
  margin-top: 0.6rem;
}

.rankExhbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.52rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.rankExhbox img:nth-child(1) {
  margin-right: 0.4rem;
}

.rankExhbox img:nth-child(3) {
  margin-left: 0.4rem;
}

.van-grid-item__content {
  background: none;
}

.van-grid {
  justify-content: center;
}

.rankvalue {
  color: #deb888;
  font-size: 0.2rem;
  font-family: Regular;
}

.avatarFrame {
  overflow-y: scroll;
  display: flex;
  white-space: nowrap;
  margin: 0 17px;
}

.ffectsimg {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}

.avatarFramebox {
  width: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 0.2rem;
}

.help {
  text-align: center;
  margin-bottom: 68px;
}

.titleName {
  color: #deb888;
  font-size: 0.23rem;
  margin-bottom: 0.2rem;
}

.van-grid-item__content {
  padding: 0 0 0.5rem;
}

.upgradebox {
  white-space: nowrap;
  /* justify-content: center; */
  margin: 0 1.17rem;
  margin-bottom: 0.25rem;
}

.contentbox {
  text-align: left;
  font-family: Regular;
  margin-left: 0.3rem;
}

.urlRank_box{
  width: 95px;
  overflow: hidden;
/* white-space: nowrap; */
/* text-overflow: ellipsis; */
}
.iosClass .head{
  padding-top: 0.88rem;
}
.iosClass .huadongg{
  /* padding-top: 0.44rem; */
}
.iosClass .beijin{
  padding-top: 2rem;
}
.iosClass .van-swipe{
  padding-bottom: 0.44rem;
}
</style>